.sectionStyle {
  height: auto;
  background-color: rgba(243, 246, 248, 1);
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
}

.imageStyle {
  width: 100%;
  max-width: fit-content !important;
  border-radius: 12px;
  margin-bottom: 20px;
}

.textContainerStyle {
  margin-left: 30px;
  flex: 1;
}

.titleStyle {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.paragraphStyle {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.6;
}

.buttonStyle {
  background-color: transparent;
  color: #051435;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.bannerStyle {
  background-color: #051435;
  height: auto;
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  border-radius: 8px;
  max-width: 1200px;
  padding: 20px 0;
  flex-wrap: wrap;
}

.statItemStyle {
  text-align: center;
  flex: 1;
  margin: 10px;
}

.statNumberStyle {
  font-size: 32px;
  font-weight: bold;
}

.statTextStyle {
  font-size: 16px;
}

@media (max-width: 768px) {
  .textContainerStyle {
    margin-left: 0;
  }
}
