.headerCycleWrapper {
  width: 10px;
  height: 10px;
  background-color: #9fdaf8;
  border-radius: 50%;
  margin-right: 20px;
  .headerCycleTitle {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
  }
}
