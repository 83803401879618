.biography-container {
  padding: 20px;
}

.ql-editor {
  min-height: 450px;
  overflow-y: auto;
}

.source-textarea {
  width: 95%;
  min-height: 520px;
  font-family: monospace;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #f8f9fa;
  resize: none;
  overflow-y: auto;
}
