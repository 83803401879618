.modal-header-banner {
  background-color: #192849;
  color: white;
  padding: 20px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.modal-header-banner .close {
  color: white;
  opacity: 1;
}

.modal-body {
  padding: 30px;
}

button {
  background-color: #007bff;
  border: none;
  color: white;
}
