.imageStyle {
  width: 100%;
  max-width: fit-content !important;
  border-radius: 12px;
  margin-bottom: 20px;
}

.textContainerStyle {
  margin-left: 30px;
  flex: 1;
}

.titleStyle {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.paragraphStyle {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.6;
}

.buttonStyle {
  background-color: transparent;
  color: #051435;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .textContainerStyle {
    margin-left: 0;
  }
}
