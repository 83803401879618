.languageSwitcherContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.languageButtons {
  display: flex;
  border-radius: 20px; /* Rounded border for the entire switcher */
  overflow: hidden; /* Prevent the buttons from overflowing */
  background-color: rgba(
    0,
    0,
    0,
    0.22
  ); /* Light background for the switcher container */
}

.languageButton {
  background-color: transparent;
  border: none;
  padding: 5px;
  margin: 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  color: #5d5c5c;
  border-radius: 20px; /* Rounded buttons */
  flex: 1; /* Make both buttons fill the available space */
}

.active {
  background-color: #051435; /* Blue background when active */
  color: #fff; /* White text when active */
  margin: 2px;
}

@media (max-width: 768px) {
  .languageSwitcherContainer {
    align-items: baseline;
  }
}
