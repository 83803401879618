.headerCycleWrapper {
    width: 10px;
    height: 10px;
    background-color: #9fdaf8;
    border-radius: 50%;
    margin-right: 20px;
    .headerCycleTitle {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }


  .imageStyle {
    height: 250px;
    border-radius: 12px;
    width: 100%;
    max-height: 250px;
  }
  
  @media screen and (max-width: 768px) {
    .html__timeline figure  {
        max-width: 100% !important;
        width: auto !important;
        height: auto !important; /* Ensures the height scales properly */
    }

    .html__timeline img {
        max-width: 100% !important;
        width: auto !important;
        height: auto !important; /* Ensures the height scales properly */
    }
}

.html__timeline p {
  text-align: justify;
}
