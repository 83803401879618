.button__style {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: max-content;
  padding: 10px 20px;
}

@media screen and (max-width: 768px) {
  .button__style {
    width: 100%;
  }
}
