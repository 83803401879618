/* spinner.css */
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Fullscreen center */
}

.spinner div {
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
