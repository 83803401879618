.pdfContainer {
  width: 405.33px;
  height: 190px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #dddddd;
  margin: 10px;
}

@media (max-width: 768px) {
  .mobile__size {
    font-size: 25px !important;
  }
}
