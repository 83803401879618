#masinijada_content {
  h2 {
    font-size: 25px;
    margin: 20px 0;
  }

  .masinijada-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  table {
    width: 100% !important;
  }
  .masinijada-table caption {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: left;
  }

  .masinijada-table th,
  .masinijada-table td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #ddd;
    font-size: 14px;
  }
  .masinijada-table tr:first-child th:first-child {
    text-align: center !important;
    background-color: transparent !important;
    span {
      color: #333 !important;
      font-size: 18px !important;
    }
  }

  .masinijada-table th {
    background-color: rgb(5, 20, 53);
    color: white;
    text-transform: uppercase;
    font-weight: bold;

    span {
      font-size: 14px !important;
    }
  }

  .masinijada-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .masinijada-table tbody tr:hover {
    background-color: #e0f7fa;
  }

  .masinijada-table th:first-child {
    width: 40% !important;
  }

  .masinijada-table th:nth-child(2) {
    width: 20% !important;
  }

  .masinijada-table th:nth-child(3) {
    width: 40% !important;
  }

  /* Style for second td */
  .masinijada-table td:nth-child(2) {
    font-size: 28px !important;
    text-align: center;
  }

  /* @media screen and (max-width: 768px) {
          .masinijada-table {
            display: block;
            overflow-x: auto;
            white-space: nowrap;
            margin-bottom: 20px;
          }
        
          .masinijada-table caption {
            text-align: center;
            font-size: 18px;
            margin-bottom: 15px;
          }
        
          .masinijada-table th,
          .masinijada-table td {
            padding: 10px 8px;
            font-size: 12px;
          }
        
          .masinijada-table tbody {
            display: flex;
            flex-direction: column;
          }
        
          .masinijada-table tbody tr {
            display: block;
            margin-bottom: 10px;
          }
        
          .masinijada-table tbody tr td {
            display: block;
            text-align: right;
            padding: 8px 0;
          }
        
          .masinijada-table tbody tr td:before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
          }
        } */
}
