.cardWrapper {
  background-color: #f3f6f8;
  border-radius: 16px;
  padding: 16px;
  max-width: 800px;
}

.newsContent {
  max-width: 800px;
  padding: 18px;
}
