.dekanatCard {
  width: 624px;
  height: 300px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #dddddd;
  background-color: white;
  cursor: pointer;
  .dekanatPosition {
    padding: 8px 16px;
    background-color: #f3f6f8;
    border-radius: 0px 12px 0px 12px;
    color: #0094ff;
    font-size: 12px;
    display: inline-block;
    margin-bottom: 10px;
    width: max-content;
  }
}
