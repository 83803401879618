.izjaveContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 16px;
}

.izjavaItem {
  flex-grow: unset;
  flex: 1 1 calc(33.33% - 16px);
  margin-bottom: 16px;
  max-width: 33%;
}

@media (max-width: 768px) {
  .izjavaItem {
    max-width: 100%;
    flex: 1 1 100%;
  }
}
