/* Default style for the navbar */
.sectionNavbar {
  background-color: rgba(159, 218, 248, 1);
  height: 69px;
  z-index: 999;
}

/* Sticky navbar when it reaches the top */
.sticky {
  position: fixed;
  top: 69px; /* Adjust based on your main navbar height */
  left: 0;
  right: 0;
  width: 100%;
  transition: top 0.3s ease; /* Smooth transition */
}

/* To ensure the navbar takes the full width when sticky */
.sticky-container {
  position: relative;
}

/* const navbarStyle = {
  backgroundColor: "rgba(159, 218, 248, 1)",
  height: "69px",
}; */

.linkStyle {
  color: black;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  padding: 10px;
  margin-right: 10px;
}

.linkStyle:hover {
  border-bottom: 2px solid #0094ff !important;
}
