/* Container styles */
.containerWrapper {
  background-color: white;
}

/* Card styles */
.cardCustom {
  padding: 24px;
  border: 1px solid #dddddd;
  border-radius: 12px;
}

/* Button styles */
.sectionButtons {
  text-decoration: none;
  color: inherit;
}
