/* Section Styles */
.sectionWrapper {
  background-color: #f3f6f8;
  padding: 40px 0;
}

/* Title and Paragraph Styles */
.sectionTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: start;
  margin-bottom: 20px;
}

.sectionSubtitle {
  font-size: 16px;
  text-align: start;
  margin-bottom: 40px;
}

/* Card Styles */
.cardCustom {
  border: 1px solid rgba(221, 221, 221, 1);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: start;
  display: flex;
  flex-direction: column;  /* Ensure the text is in a column */
  height: 100%;  /* Fill the column height */
  min-height: 150px; /* Optional: set a minimum height for the cards */
  justify-content: space-between; /* Ensure text inside is spaced evenly */
  box-sizing: border-box; /* Make sure padding is included in total height/width */
}

.cardTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;  /* Slight space between title and text */
  flex-shrink: 0; /* Prevent the title from shrinking */
}

.cardText {
  font-size: 14px;
  margin-top: 0; /* Remove top margin */
  margin-bottom: 0; /* Remove bottom margin */
  flex-grow: 1;  /* Allow the text to grow and fill remaining space */
}

/* Card Container Styles */
.cardContainer {
  min-height: 150px;
}

/* Flex Column for Cards */
.cardColumn {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  justify-content: space-between;  /* Ensure the content in the column is spaced evenly */
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;  /* Added gap to ensure spacing between cards */
}

/* Ensure that all cards have the same width */
.col-lg-4, .col-md-6, .col-sm-12 {
  display: flex;  /* Enable flex behavior to ensure equal width */
  justify-content: center; /* Center cards within the column */
}

