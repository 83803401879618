.arrowStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  color: grey;
  cursor: pointer;
  position: absolute;
  border: 1px solid #dddddd;
  z-index: 2;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.nextArrow {
  bottom: -60px;
  right: 20px;
}

.prevArrow {
  bottom: -60px;
  right: 70px;
}
.imageStyle {
  height: 250px;
  width: 100%;
  object-fit: contain;
  border-radius: 12px;
}
.cardStyle {
  border-radius: 12px;
  border: 1px solid #dddddd;
  /* width: 49.5%; */
  padding: 24px;
}
.departmentCard {
  border: 1px solid #dddddd;
  border-radius: 12px;
  padding: 24px;
  background-color: #ffffff;
  min-height: 220px;
}
