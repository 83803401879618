.linkStyle {
  text-decoration: none;
  cursor: pointer;
}

.iframeStyle {
  border: None;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  height: 1000px; /* Default height for desktops */
}

/* Adjust height for smaller screens */
@media (max-width: 768px) {
  .iframeStyle {
    height: 700px; /* Smaller height for tablets & mobile */
  }
}

@media (max-width: 480px) {
  .iframeStyle {
    height: 600px; /* Even smaller height for phones */
  }
}
