.profile-container {
  padding: 20px;
  background-color: #f4f4f4;
  min-height: 100vh;
}

.card {
  background: white;
  border-radius: 12px;
}

.profile-img-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-save {
  border: none;

  cursor: not-allowed;
}

@media (max-width: 768px) {
  .image-container {
    .profile-img-container {
      width: 100px;
      height: 100px;
    }
    button {
      font-size: 12px;
    }
  }
}
