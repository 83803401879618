.card {
  border: 1px solid #dddddd;
  border-radius: 12px;
  padding: 24px;
  background-color: #ffffff;
  height: 220px;
  display: flex;
  flex-direction: column;
}

.cardIcon {
  font-size: 24px;
  margin-bottom: 10px;
}

.cardTitle {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  margin-bottom: auto;
}

.cardImage {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 10px;
}

.cardButton {
  background-color: #9fdaf8;
  color: #000;
  margin-top: auto;
}
