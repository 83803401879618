.universityTitle {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}
.sectionTitle {
  color: #9fdaf8;
  font-size: 12px;
}
.rightsText {
  color: #a9a9a9;
  font-size: 14px;
  font-weight: bold;
}
.footerLinkStyle {
  font-size: 14px;
  background: none;
  border: none;
}
