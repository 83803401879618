.image__wrapper {
  position: relative;
  width: 100%;
  /* max-width: 250px; */
  height: 250px;
  border-radius: 12px;
  overflow: hidden;
  /* border: 1px solid #ddd; */

  .search__icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
  }
}
.imgStyle {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.slick-next:before,
.slick-prev:before {
  color: black !important;
}
.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Optional for spacing */
  box-sizing: border-box;
}

.slick-list {
  overflow: hidden; /* Ensure no overflow issues */
}

.slick-track {
  display: flex; /* Align slides properly */
}

.arrowStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  color: grey;
  cursor: pointer;
  position: absolute;
  bottom: -60px;
  right: 70px;
  border: 1px solid #dddddd;
}

.nextArrow {
  bottom: -60px;
  right: 20px;
}

.prevArrow {
  bottom: -60px;
  right: 70px;
}
.galleryCardWrapper {
  position: relative;
  width: 100%;
  max-width: 250px;
  height: 250px;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #ddd;
}

.searchIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
}
.closeIcon {
  position: absolute;
  top: 10px;
  right: 15px;
  background: black;
  border-radius: 50%;
  border: none;
  font-size: 1.5rem;
  color: #fff;
  z-index: 1051;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .image__wrapper {
    width: 100%;
    max-width: 100%;
  }
}
