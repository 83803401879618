.arrowStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  color: grey;
  cursor: pointer;
  position: absolute;
  border: 1px solid #dddddd;
  z-index: 2;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.hidden {
  display: none;
}
.nextArrow {
  right: -20px;
  top: 50%;
}

.prevArrow {
  left: -20px;
  top: 50%;
}

.cardContainer {
  width: 100%;
  height: 350px;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  position: relative;
}

.cardImage {
  width: 100%;
  height: 40%;
  object-fit: cover;
}

.cardDate {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 12px;
  color: #333;
}

.cardContent {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
}
