/* variables.css */
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap");

/* body {
  font-family: "Archivo", sans-serif !important;
} */

:root {
  --heading-1-font-size: 52px;
  --heading-2-font-size: 48px;
  --heading-3-font-size: 40px;
  --heading-4-font-size: 32px;
  --heading-5-font-size: 24px;
  --heading-6-font-size: 20px;

  --large-1-font-size: 18px;
  --base-1-font-size: 16px;
  --medium-1-font-size: 14px;
  --small-1-font-size: 12px;

  --heading-1-font-size-mobile: 40px;

  --subtitle-font-size: 16px;
  --font-color-heading-1: #ffffff;
  --font-color-heading-2: #000000;
  --font-color-subtitle: #ffffff;
  --border-color: #dddddd;
  --border-radius: 8px;
  --border-padding: 10px;
  /* --font-family: "Archivo", sans-serif; */

  --card-title-font-size: 20px;

  --paragraph-font-size: 16px;
}

.heading-1 {
  font-size: var(--heading-1-font-size);
  color: var(--font-color-heading-1);
  font-weight: 500;
  margin: 0;
}

.heading-subtitle {
  font-size: var(--large-1-font-size);
  color: var(--font-color-heading-1);
  font-weight: 400;
  margin: 0;
}
.section_title {
  font-size: var(--heading-3-font-size);
  color: var(--font-color-heading-2);
  font-weight: 500;
  /* width: 90%; */
  margin: 0;
}

.section_subtitle {
  font-size: var(--base-1-font-size);
  color: inherit;
  font-weight: 400;
  /* width: 100%; */
  margin: 0;
}

.professor-card_subtitle {
  font-size: 12px;
  color: inherit;
  font-weight: 400;
  margin: 0;
}

.card__title {
  font-size: var(--heading-6-font-size);
  color: var(--font-color-heading-2);
  font-weight: 500;
  margin: 0;
}

.card__subtitle {
  font-size: var(--base-1-font-size);
  color: var(--font-color-heading-2);
  font-weight: 400;
  margin: 0;
}
.document__title {
  font-size: var(--base-1-font-size);
  color: var(--font-color-heading-2);
  font-weight: 600;
  margin: 0;
}

.footer__link {
  font-size: var(--medium-1-font-size);
  color: var(--font-color-heading-1);
  font-weight: 400;
  margin-bottom: 8px;
  cursor: pointer;
}

.footer__link:hover {
  color: lightblue !important;
}

.footer__link a {
  text-decoration: none; /* Removes underline */
  color: inherit; /* Inherits color from the parent */
}

.border {
  border: 1px solid var(--border-color);
  border-radius: 12px;
}

.border-top {
  border-top: 1px solid var(--border-color);
}

.border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.dark_btn {
  background-color: #051435;
  color: var(--font-color-heading-1);
  padding: 4px 20px;
}

.light_btn {
  background-color: #9fdaf8;
  color: #051435;
  padding: 4px 20px;
}

.card-title {
  font-size: var(--card-title-font-size);
  font-weight: 500;
  color: #000000;
}

.paragraph {
  font-size: var(--paragraph-font-size);
  font-weight: 400;
  color: #000000;
}

.container__wrapper {
  padding: 40px !important;
}

.card__wrapper {
  margin: 0 !important;
}

.section__buttons {
  width: max-content;
}

.document__wrapper {
  height: 190px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
}

.search-bar {
  border: 1px solid #dddddd;
  border-radius: 10px;
  height: 38px;
}

.html__wrapper {
  width: 60%;
}

.html__text a {
  text-decoration: none; /* Remove underline initially */
  color: #38579b; /* Keep link color consistent */
}

.html__text a:hover {
  text-decoration: underline; /* Add underline on hover */
  color: #007bff; /* Optional: Change color on hover */
}

.html__text img,
.html__wrapper img {
  max-width: 100%;
  height: auto;
}

.professor-image {
  width: 60px; /* Initial width */
  height: 60px; /* Initial height */
  transition: transform 0.3s ease, border-radius 0.3s ease; /* Smooth transition for transform and border-radius */
  border-radius: 50%;
  z-index: 9;
}

.professor-image:hover {
  transform: scale(2); /* Increase size by 20% */
  /* border-radius: 0%; Remove the circular shape */
}

@media (max-width: 768px) {
  .heading-1 {
    font-size: var(--heading-1-font-size-mobile);
  }
  .container__wrapper {
    padding: 30px 10px !important;
  }
  .html__wrapper {
    width: 100%;
  }

  .section_title {
    width: 100%;
  }
  .section_subtitle {
    width: 100%;
  }

  /* .card__wrapper {
    width: 95%;
  } */

  /* .departments__wrapper {
    padding: 10px 0;
    .cards__mobile {
      width: 300px !important;
    }
    .slick-slide {
      width: 300px !important;
      margin: 0 10px !important;
    }
  } */

  /* .infinite_carousels {
    padding: 10px 0;
    .slick-slide {
      width: 180px !important;
      height: 180px !important;
      margin-right: 10px !important;
    }

    .carousel_width {
      width: 180px !important;
      height: 180px !important;
    }
  } */
  .section__buttons {
    width: 100%;
  }
}

iframe {
  max-width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  display: block;
  margin: 0 auto;
}
