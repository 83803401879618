.inputField {
  width: 100%;
  max-width: 240px;
}

.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.examSubject {
  min-height: 60px;
}

.professorImage {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.downloadLink {
  text-decoration: none;
  color: #000000;
  font-size: 0.95rem;
}

.professorLink {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 768px) {
  .inputField {
    max-width: 100%;
  }
}
