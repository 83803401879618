/* ScrollToTop.css */
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255);
  color: #192849;
  border: 1px solid; /* Updated border color */
  border-color: #192849;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-to-top-button:hover {
  background-color: #192849; /* Change to white on hover */
  color: #fff;
  border-color: #fff;
}
