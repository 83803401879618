.bannerWrapper {
  height: min-content;
  background-color: transparent;
  color: white;
  margin-top: 150px;
}
.bannerImg {
  width: 100%;
  max-width: 1280px;
  max-height: 320px;
  border-radius: 25px;
}
.bannerDescription {
  width: 70%;
}

@media (max-width: 768px) {
  .bannerImg {
    height: 320px;
    object-fit: cover;
  }
  .bannerDescription {
    width: 100%;
  }
}
