.statementsWrapper {
  min-height: 350px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 24px;
  background-color: #ffffff;
  .statementsText {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 1.6;
  }
  .profileImage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
  }
  .positionSubtitle {
    font-size: 12px;
    color: #000000;
    margin-bottom: 0;
  }
}
