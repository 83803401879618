.slick-slide {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slick-active {
  opacity: 1;
}

.statNumberStyle {
  font-size: 32px;
  font-weight: bold;
  color: white;
}

.statTextStyle {
  font-size: 16px;
  color: white;
}

.bannerStyle {
  position: relative;
  width: 80%;
  height: auto;
  overflow: hidden;
  background-color: #051435;
  border-radius: 8px;
  max-width: 1200px;
}

@media (max-width: 768px) {
  .mobileGroupStyle {
    display: flex;
    flex-direction: column;
  }

  .mobileStatsContainer {
    display: flex !important;
    flex-direction: column !important;
  }
}

.statItemStyle {
  text-align: center;
  flex: 1;
  margin: 20px;
}
