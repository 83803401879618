.teaching-container {
  padding: 20px;
  min-height: 100vh;
  .name-link {
    text-decoration: none;
    color: #38579b;
    &:hover {
      cursor: pointer;
      color: #007bff;
    }
  }
}

.card {
  background: white;
  border-radius: 10px;
  padding: 20px;
}

.teaching-container .profile-img {
  border-radius: 50%;
  object-fit: cover;
  width: 100px;
  height: 100px;
}
