.line-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #051435;
  z-index: -1; /* Places the background behind all other content */
}

@keyframes lineAnimation {
  0% {
    transform: translateY(0) scaleX(1);
  }
  50% {
    transform: translateY(100vh) scaleX(2); /* Grow width at the midpoint */
  }
  100% {
    transform: translateY(0) scaleX(1);
  }
}

.line {
  width: 1px;
  height: 100%;
  background-color: grey;
  position: absolute;
  top: 0;
  animation: lineAnimation 3s ease-in-out infinite;
  opacity: 0.2;
}
