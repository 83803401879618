.dropDownStyle {
  border: 1px solid #dddddd;
  background-color: white;
  text-align: left;
  border-radius: 6px;
}
.searchInputStyle {
  border: 1px solid #dddddd;
  background-color: white;
  border-radius: 6px;
}
.tableHeadingStyle {
  color: #a9a9a9;
  background-color: #f3f6f8;
  font-size: 12px;
  font-weight: 400;
}
.downloadHeading {
  text-align: center;
}
.tableDataStyle {
  background-color: transparent;
  font-size: 14px;
}
.downloadButtonStyle {
  border: none;
  background: transparent;
  cursor: pointer;
  color: black;
}
@media (max-width: 768px) {
  .dropDownStyle {
    button {
      width: 100%;
      text-align: start;
    }
  }
}
