.dropDownStyle {
  border: 1px solid #dddddd;
  background-color: white;
  text-align: left;
  border-radius: 6px;
}
.announcementContainer {
  border-top: 1px solid var(--border-dark, #dddddd);
  padding-top: 40px;
}
.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.announcementCard {
  width: 100%;
  height: min-content;
  border: 1px solid #dddddd;
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .announcementDate {
    font-size: 12px;
    color: #051435;
    width: 120px;
    height: 35px;
    text-align: end;
  }
}

@media (max-width: 768px) {
  .dropDownStyle {
    button {
      width: 100%;
      text-align: start;
    }
  }
}
