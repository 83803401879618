.headingWrapper {
  min-height: min-content;
  background-color: transparent;
  position: relative;
  margin-top: 150px;
}
.headingImage {
  width: 100%;
  max-width: 1280px;
  max-height: 320px;
  height: auto;
  border-radius: 16px;
  object-fit: cover;
}
.arrowBack {
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 3px;
}
