.cardWrapper {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  position: relative;
}
.imageStyle {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.newsDate {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 12px;
  color: #333;
}
.newsContent {
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.newsSummary {
  font-size: 14px;
  color: "#555";
}

.conferenceHeading {
  margin-bottom: 30px;
  margin-top: 10px;
}

.inputField {
  width: 100%;
  max-width: 240px;
}
@media (max-width: 768px) {
  .inputField {
    max-width: 100%;
  }
}
